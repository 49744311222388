import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getDatabase, ref, push } from "firebase/database"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCoZbosE7_jI9VRXiiw34WORz8k-QPDl8I",
    authDomain: "tate-diamonds-page.firebaseapp.com",
    databaseURL: "https://tate-diamonds-page-default-rtdb.firebaseio.com",
    projectId: "tate-diamonds-page",
    storageBucket: "tate-diamonds-page.firebasestorage.app",
    messagingSenderId: "1080891954126",
    appId: "1:1080891954126:web:a583e7bd36ae1f8939919f",
    measurementId: "G-K0GT31LPNV"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  export const database = getDatabase(app);