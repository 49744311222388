import React, { useState } from "react"
import logo from "./TaTe_Logo_Transparent.png"
import ctaImage from "./diamond_image.png"
import { Link } from "react-router-dom";
import { database } from "./firebase";
import { ref, push } from "firebase/database";
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"
import AlertModal from "./AlertModal"
import './App.css';

function App() {

  const [email, setEmail] = useState("");
  const [modal, setModal] = useState({open: false, message: ""})


  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email.trim() || !/\S+@\S+\.\S+/.test(email)) {
      setModal({open: true, message: "Please enter a valid email address."})
      return;
    }

    // Reference to the database path
    const emailsRef = ref(database, "emails")

    // Push emails/data to the database
    push(emailsRef, { email })
      .then(() => {
        setModal({open: true, message: "Thank you for subscribing to our newsletter!"})
        setEmail("")

      })
      .catch((error) => {
        setModal({open: true, message: "Failed to register for newsletter! Please try again."})
      });
  };

  const closeModal = () => {
    setModal({open: false, message: ""})
  }


  return (
    <>
      <div className="main">
        <img className="logo" src={logo} alt="Tate Diamonds logo" />
        <div className="cta">
          <div className="drag"></div>
          <i className="fa-solid fa-x" />
          <img className="cta--img" src={ctaImage} alt="" />
          <div className="cta--form">
            <h1 className="cta--header">Be The First To Know</h1>
            <form action="" onSubmit={handleSubmit}>
              <input type="email"
                placeholder="Enter email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button type="submit">Sign Up</button>
            </form>
            {/* <iframe src="https://forms.monday.com/forms/embed/606c9a711f9c593fa22377d948e415cb?r=apse2"
              width="650"
              height="500"
              style={{ border: "0", boxShadow: "5px 5px 56px 0px rgba(0,0,0,0.25)" }}
            ></iframe> */}
            <p className="cta--description">{modal.message} COMING SOON! Join our newsletter for exclusive access to the official site launch & our new collections.</p>
          </div>
          <div className="socials--text-mobile">
            <p>Brilliantly Rare. Proudly Namibian.</p>
            <div className="socials--icons-mobile">
              <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/share/1D8r7zrSxz/?mibextid=LQQJ4d"><i className="fa-brands fa-facebook social-icon-mobile" /></a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/tatediamonds/"><i className="fa-brands fa-instagram social-icon-mobile" /></a>
              <a target="_blank" rel="noopener noreferrer" href="mailto:sales@tatediamonds.com"><i className="fa-solid fa-envelope social-icon-mobile" /></a>
            </div>
          </div>
        </div>
        <div className="socials--text">
          <p>Brilliantly Rare. Proudly Namibian.</p>
          <div className="socials--icons">
            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/share/1D8r7zrSxz/?mibextid=LQQJ4d"><i className="fa-brands fa-facebook social-icon" /></a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/tatediamonds/"><i className="fa-brands fa-instagram social-icon" /></a>
            <a target="_blank" rel="noopener noreferrer" href="mailto:sales@tatediamonds.com"><i className="fa-solid fa-envelope social-icon" /></a>
          </div>
        </div>
      </div>

      < AlertModal
      open={modal.open}
      message={modal.message}
      onClose={closeModal}
      />
      < Analytics/>
      < SpeedInsights/>
    </>
  );
}

export default App;
