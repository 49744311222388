import React, { useState, useEffect } from "react"

const AlertModal = ({ open, message, onClose }) => {

    if (!open) return null;

    return (
        <>
            <div className="modal--background">
                <div className="modal--main">
                    <p>{message}</p>
                    <button onClick={onClose}>Ok</button>
                </div>
            </div>
        </>
    )
}

export default AlertModal